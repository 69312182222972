import { AppInsightsCore, IExtendedConfiguration, safeGetCookieMgr, ICookieMgr, getCookieValue, ITelemetryItem } from "@microsoft/1ds-core-js";
import {ApplicationInsights, IContentUpdateOverrideValues, IWebAnalyticsConfiguration} from "@microsoft/1ds-wa-js";
import {
    PropertiesPlugin, IPropertyConfiguration,
} from "@microsoft/1ds-properties-js";
import {PostChannel} from "@microsoft/1ds-post-js";
import {CorrelationVectorPlugin} from "@microsoft/1ds-cv-js";
import {QosPlugin} from "@microsoft/1ds-qos-js";
// global variables available for TypeScript.
import {CustomWindow} from './CustomWindow';
import {LogPolling} from "./LogPolling";
import {LogQueue} from "./LogQueue";
import {IUserContentDetails} from "@microsoft/1ds-properties-js/src/DataModels";
import {ClientCommunicator} from "./ClientCommunicator";
import { CredScanRedactor } from "./CredScanRedactor";

// cast global var window with CustomWindow
declare let window: CustomWindow;
// declaring global const variable to detect GPC signal for user OptIn for data sharing
// This value is set via UHF detection logic script.
// Ref: https://microsoft.sharepoint.com/:w:/t/CSM-Sites-Data-As-A-Service/EXyEpT0oL29Mnav40cSw8_YB2XhHfKkxrKC6OZI9mrzPlg?e=MYDhJ4
declare const GPC_DataSharingOptIn: boolean;
let GPC_DataSharingOptInLocal: boolean;
GPC_DataSharingOptInLocal = (typeof GPC_DataSharingOptIn == 'undefined' || GPC_DataSharingOptIn == null) ?  false : GPC_DataSharingOptIn;
const cmsVersion = window.traceid ? "CascadeV2" : "Cascade"; // The goal here is to determine whether the published pages are being displayed from the older Stack or the new V2 stack.
const onedsConfigElement = document.querySelector(".oneds-config");
let instrumentationKey = onedsConfigElement.getAttribute("data-instrumentationkey");
let urlCollectQuery = onedsConfigElement.getAttribute("data-urlCollectQuery") === 'true';
let urlCollectHash = onedsConfigElement.getAttribute("data-urlCollectHash") === 'true';
let autoCapturelineage = onedsConfigElement.getAttribute("data-autoCapturelineage") === 'true';
let autoCaptureresize = onedsConfigElement.getAttribute("data-autoCaptureresize") === 'true';
let autoCapturescroll = onedsConfigElement.getAttribute("data-autoCapturescroll") === 'true';
let OneDSIntializeEventName = onedsConfigElement.getAttribute("data-initialize1DSEventName");
let max1DSInitializeDelayInSeconds = onedsConfigElement.getAttribute("data-max1DSInitializeDelayInSeconds");
let tenantName = onedsConfigElement.getAttribute("data-tenantName");
let tenantTitle = onedsConfigElement.getAttribute("data-tenantTitle");
let tenantDomain = onedsConfigElement.getAttribute("data-tenantDomain");
let tenantSiteName = onedsConfigElement.getAttribute("data-tenantSiteName");
let tenantNameProperty = onedsConfigElement.getAttribute("data-tenantNameProperty");
let tenantTitleProperty = onedsConfigElement.getAttribute("data-tenantTitleProperty");
let tenantDomainProperty = onedsConfigElement.getAttribute("data-tenantDomainProperty");
let tenantSiteNameProperty = onedsConfigElement.getAttribute("data-tenantSiteNameProperty");

const appInsightsCore: AppInsightsCore = new AppInsightsCore();
const webAnalyticsPlugin: ApplicationInsights = new ApplicationInsights();
const propertiesPlugin: PropertiesPlugin = new PropertiesPlugin();
const collectorChannelPlugin: PostChannel = new PostChannel();
const correlationVectorPlugin: CorrelationVectorPlugin = new CorrelationVectorPlugin();
let propertiesPluginConfig: IPropertyConfiguration = null;
const qosPlugin: QosPlugin = new QosPlugin();
const telemetryLog: LogQueue = new LogQueue();
let myConsentDetails: IUserContentDetails = null;
let _cookieMgr: ICookieMgr = safeGetCookieMgr(appInsightsCore);
let loopCounter = 0;
let initialize1DSStatus = false;
let intervalId = -123;
let userConsentStatus = false;
let communicator: ClientCommunicator;
let credScanRedactor: CredScanRedactor = new CredScanRedactor();

if (typeof (window.WcpConsent) != "undefined" && window.WcpConsent != null) {
    //thirdparty function is called upon initialization
    window.WcpConsent.onInitCallback(updateConsentDetails);
    //thirdparty function is called upon consent changed by the user
    window.WcpConsent.onConsentChanged(updateConsentDetails);
}

if(onedsConfigElement.getAttribute("data-env") === "dev" ) {
    // Disabled generation of  componentId in data-m attribute for SG release (BUG 6964025)
    // function only removes data-component-id attribute
    _initComponentIds();
}

//Additional user authentication related information about cookie RPSSecAuth set as a property "CookiePresentRPSSecAuth"
// ESI always creates window.msauth when cookie RPSSecAuth present but it may not contain information
propertiesPlugin.setProperty("CookiePresentRPSSecAuth", !!(window.msauth));

/**
 *  Custom Meta tags "data-tags" represent products on HTML page.
 *  this method extract products for telemetry
 */
function _processDataTags(): { [name: string]: object } {
    let tagArray: { [name: string]: object } = {};
    const tags = document.querySelectorAll('[data-tags]');
    for (let i in Array.from(tags)) {
        let jsonValue = tags[i].getAttribute("data-tags");
        let metaTagJsonObject: any = JSON.parse(jsonValue);
        let productNames = Object.getOwnPropertyNames(metaTagJsonObject);
        for (let i in productNames) {
            if (productNames[i] in metaTagJsonObject) {
                tagArray[productNames[i]] = metaTagJsonObject[productNames[i]];
            }
        }
    }
    return tagArray;
}

/**
 * Add additional properties to PageTags
 * @param pageTags current pageTags object
 * @param property: new property
 * @param value: new value
 * @return new pageTags object
 */
function _extendPageTags(pageTags: { [name: string]: string | object }, property: string, value: string): { [name: string]: string | object } {
    pageTags[property] = value;
    return pageTags
}
/**
 * create data-m attribute before initialization of 1DS
 * @private
 */
function _initComponentIds() {
    //var lang = document.querySelector('html').getAttribute('lang');
    const pageComponents = document.querySelectorAll('[data-component-id]');
    for (let i in Array.from(pageComponents)) {
        // Disabled generation of  componentId in data-m attribute for SG release (BUG 6964025)
        // let componentId = pageComponents[i].getAttribute("data-component-id");
        // var jsonComponentId = "{\"componentId\":\"" + componentId + "-" + lang + "\"}";
        // pageComponents[i].setAttribute("data-m", jsonComponentId);
        pageComponents[i].removeAttribute('data-component-id');
    }
}

function getSyncMuidStatus(): boolean{
    populateConsentFromMSCCCookie();
    return myConsentDetails && myConsentDetails.Analytics && myConsentDetails.Advertising && GPC_DataSharingOptInLocal;
}

function getConsentDetails(){
    //for the initialization of the page the myConsentDetails is always null
    if(myConsentDetails === null){
        populateConsentFromMSCCCookie();
    }
    return myConsentDetails;
}

function updateConsentDetails(newConsent: any) {
    myConsentDetails = newConsent;
}

function getMSCCCookieName(): string {
    return (typeof propertiesPluginConfig !== 'undefined' && propertiesPluginConfig
        && typeof propertiesPluginConfig.userConsentCookieName !== 'undefined' && propertiesPluginConfig.userConsentCookieName)
        ? propertiesPluginConfig.userConsentCookieName : "MSCC";
}

/**
The function ensures that the pageview event has the consentDetails data.
*/
function populateConsentFromMSCCCookie(){
    let cookieName = getMSCCCookieName();
    let cookieValue = getCookieValue(_cookieMgr, cookieName);
    let cookieConsent:IUserContentDetails = null;


    if(typeof cookieValue !== 'undefined' && (cookieValue !== null || cookieValue !== "")){
         // If cookieValue is "NR" and GPC_DataSharingOptInLocal is true, set all consent types to true
        if(cookieValue === "NR" && GPC_DataSharingOptInLocal){
            cookieConsent = {
                Required: true,
                Analytics: true,
                SocialMedia: true,
                Advertising: true,
            };
        }
        // If cookieValue contains "c1=", "c2=", and "c3=", set consent types based on their values
        // it was seen that at times we have a MUID that has a different cookie value.  Addressing that.
        else if(cookieValue.includes("c1=") && cookieValue.includes("c2=") && cookieValue.includes("c3=")) {
            cookieConsent = {
                Required: true,
                Analytics: (cookieValue.includes("c1=2")) ? true : false,
                SocialMedia: (cookieValue.includes("c2=2")) ? true : false,
                Advertising: (cookieValue.includes("c3=2")) ? true : false,
            };
        }
        // If GPC_DataSharingOptInLocal is true and cookieValue is empty, set all consent types to false
        if(GPC_DataSharingOptInLocal && cookieValue === "") {
            cookieConsent = {
                Required: true,
                Analytics: false,
                SocialMedia: false,
                Advertising: false,
            };
        }
        // If GPC_DataSharingOptInLocal is false and cookieValue is not "NR", set consent types based on their values
        else if (!GPC_DataSharingOptInLocal && cookieValue !== "NR") {
            cookieConsent = {
                Required: true,
                Analytics: (cookieValue.includes("c1=2")) ? true : false,
                SocialMedia: (cookieValue.includes("c2=2")) ? true : false,
                Advertising: (cookieValue.includes("c3=2")) ? true : false,
            };
        // If GPC_DataSharingOptInLocal is false and cookieValue is "NR", set all consent types to true
        } else if (!GPC_DataSharingOptInLocal && cookieValue === "NR") {
            cookieConsent = {
                Required: true,
                Analytics: true,
                SocialMedia: true,
                Advertising: true,
            };
        }
        userConsentStatus = getUserConsentStatus(cookieConsent) && GPC_DataSharingOptInLocal;
        window.hasUserConsented = userConsentStatus;
        updateConsentDetails(cookieConsent);
    }
}

function getUserConsentStatus(cookieConsent: IUserContentDetails) {
    let consentStatus = cookieConsent && cookieConsent.Analytics && cookieConsent.Advertising && cookieConsent.SocialMedia;
    return consentStatus;
}

function initialize1DS(){

    initializeGlobalTelemetry();
    initializeComponents();
    setupPropertiesPluginConfig();

    const webAnalyticsConfig: IWebAnalyticsConfiguration = {
        coreData: {
            // market and pageName are dynamic values differ from page to page
            market: onedsConfigElement.getAttribute("data-market"),
            pageName: onedsConfigElement.getAttribute("data-pagename"),
            pageTags: _extendPageTags(_processDataTags(), "client", "AEM")
        },
        manageCv: true,
        // ESI always creates window.msauth when cookie RPSSecAuth present but it may not contain information
        // If variable msauth.AuthTicket not defined or empty user is not logged in.
        isLoggedIn: !!(window.msauth&& window.msauth.AuthTicket&&window.msauth.AuthTicket.length>0),
        urlCollectQuery: urlCollectQuery, // track campaigns
        urlCollectHash: urlCollectHash,
        useShortNameForContentBlob: true, //to avoid a few reporting issues
        autoCapture: {
            scroll: autoCapturescroll,
            resize: autoCaptureresize,
            lineage: autoCapturelineage
        },
        syncMuid: getSyncMuidStatus()
    };
    const config: IExtendedConfiguration = {
        instrumentationKey: instrumentationKey,
        cookieCfg: {
                ignoreCookies: !userConsentStatus && !GPC_DataSharingOptInLocal ? [ "MicrosoftApplicationsTelemetryDeviceId", "MC1", "ANONCHK" ]
                                                                                 : [ "MicrosoftApplicationsTelemetryDeviceId" ]
            },
        extensions: [
            webAnalyticsPlugin,
            propertiesPlugin,
            qosPlugin,
            correlationVectorPlugin,
        ],
        channels: [[collectorChannelPlugin]],
        extensionConfig: {
            [qosPlugin.identifier]: {
                appId: "appId",
                enableAjaxPerfTracking: true,
                enableCvHeaders: false,
            },
        },
    };

    setupCV(config);
    // Based on Trace Id, assign appropriate CMS V2 rendering stack.
    propertiesPlugin.setProperty("CMS", cmsVersion);
    propertiesPlugin.setProperty(tenantNameProperty, tenantName);
    propertiesPlugin.setProperty(tenantTitleProperty, tenantTitle);
    propertiesPlugin.setProperty(tenantDomainProperty, tenantDomain);
    propertiesPlugin.setProperty(tenantSiteNameProperty, tenantSiteName);
    config.extensionConfig[webAnalyticsPlugin.identifier] = webAnalyticsConfig;
    config.extensionConfig[propertiesPlugin.identifier] = propertiesPluginConfig;

    //Initialize telemetry redactor
    webAnalyticsPlugin.addTelemetryInitializer((item) => {
        redact(item);
    });

    //Initialize SDK
    appInsightsCore.initialize(config, []);
    new LogPolling(webAnalyticsPlugin, (window.telemetry.telemetryLog as LogQueue).queue);
    initialize1DSStatus = true;
    var oneDSEvent = new Event('1DSInitialized', {bubbles: true});
    document.dispatchEvent(oneDSEvent);
    console.log("1DS Initialized..");
}

// Redact sensitive data
export function redact(event: ITelemetryItem) {
    credScanRedactor.redactEventData(event.data);
    credScanRedactor.redactEventBaseData(event.baseData);
}

function initializeGlobalTelemetry() {
    //Initialize global telemetry
    if(!window.telemetry) {
        window.telemetry = {
            telemetryLog:telemetryLog,
            webAnalyticsPlugin:webAnalyticsPlugin,
            appInsightsCore:appInsightsCore,
            correlationVectorPlugin:correlationVectorPlugin
        };
    }

    //Set owap global namespace. window.owap.isInitialized() == false before initialization
    if(!window.owap) {
        window.owap = webAnalyticsPlugin;
    }
}

function initializeComponents(){
    if(onedsConfigElement.getAttribute("data-env") === "dev" ) {
        // Disabled generation of  componentId in data-m attribute for SG release (BUG 6964025)
        // function only removes data-component-id attribute
        _initComponentIds();
    }
}

function setupPropertiesPluginConfig(){
    propertiesPluginConfig = {
        // Primary telemetry system is jsll. Once 1DS set as primary telemetry this value should be set 'prod' for prod environment
        env: onedsConfigElement.getAttribute("data-env"),
        gpcDataSharingOptIn : GPC_DataSharingOptInLocal,
        callback: {
            //Callback function to get user cookie consent details
            userConsentDetails: () => {
                return getConsentDetails();
            }
        }
    };
}

function setupCV(config: IExtendedConfiguration){
    if (window.mscv) {
        correlationVectorPlugin.initialize(config, appInsightsCore, []);
        let cv = correlationVectorPlugin.getCv();
        cv.setValueLegacy(window.mscv);
        cv.extend();
    }

    if (window.msservercv) {
        propertiesPlugin.setProperty("RelatedServerCV", window.msservercv);
    }
    // Add Trace Id into 1DS events to correlate published page on V2 rendering stack.
    propertiesPlugin.setProperty("x-traceId", window.traceid);
}

function doesMSCCCookieExist(): boolean{
    let cookieName = getMSCCCookieName();
    let cookieValue = getCookieValue(_cookieMgr, cookieName);
    if(typeof cookieValue !== 'undefined' && (cookieValue !== null || cookieValue !== "")){
        if((cookieValue.includes("c1=") && cookieValue.includes("c2=") && cookieValue.includes("c3=")) || cookieValue === "NR"){
            return true;
        }
    }
    return false;
}

function instantiate1DSInitialization(){
    should1DSInitializationBeDelayed()? delay1DSInitialization(): initialize1DS();
}

function should1DSInitializationBeDelayed(): boolean{
    return (OneDSIntializeEventName.toLowerCase() === "none")? false: true;
}

//based on the maxDelayinSeconds
function getMaxLoops(){
    let maxDelay: number = +max1DSInitializeDelayInSeconds;
    let maxLoops = (maxDelay * 1000) / 100;
    return maxLoops;
}

function isReadyToInitialize(consentCookieAvailable: boolean, delayExceeded: boolean): boolean{
    if(delayExceeded) return true;

    if(OneDSIntializeEventName.toLowerCase() === "consent"){
        return consentCookieAvailable;
    }
    //console.log("Inside isReadyToInitialize and userConsentStatus = "+userConsentStatus);
    //when event name is defined by tenant.
    if(userConsentStatus){
        if(typeof(communicator) === 'undefined' || communicator === null){
            communicator = new ClientCommunicator(OneDSIntializeEventName);
        }
        communicator.activateClientCommunications();
        return (communicator.clientActionDoneStatus);
    }
}

// Delay initialization when configured for specific tenant
function should1DSbeInitialized(){
    loopCounter++;
    let maxLoops = getMaxLoops();
    if(!initialize1DSStatus) {
        let msccCookieFound = doesMSCCCookieExist();
        if(msccCookieFound){
            populateConsentFromMSCCCookie();
        }

        let delayExceeded = loopCounter >= maxLoops; // starts at 1 and runs maxLoops times
        // Initialize 1DS if mscc cookie is found or time delay is exceeded
        // and then clear the setInterval
        //console.log("calling isReadyToInitialize and delay exceed value =" +delayExceeded);
        if(isReadyToInitialize(msccCookieFound, delayExceeded)) {
            initialize1DS();
            clearInterval(intervalId);
        }
    }

}

function delay1DSInitialization(){
    intervalId = window.setInterval(should1DSbeInitialized, 100);
}

instantiate1DSInitialization();

/**
 * Exposes a window level function to update pageTags with experimentation actvity data for Adobe Analytics data collection.
 * @param overrideValues the experimentation activity with analytics payload and experiments details
 */
const experimentionContentUpdate = function experimentionContentUpdate(overrideValues:IContentUpdateOverrideValues)  {
    webAnalyticsPlugin.captureContentUpdate(overrideValues);
}

/**
* Exposes a window level function to know whether an ApplicationInsights object is initialized.
* @return boolean value true if ApplicationInsights object is initilized; otherwise false.
*/
const isInitialized = function isInitialized(): boolean {
    return webAnalyticsPlugin.isInitialized();
}

window.expAnalytics = {isInitialized,experimentionContentUpdate};