import {
    IMsDependencyTelemetry,
    IMsEventTelemetry,
    IMsExceptionTelemetry,
    IMsTraceTelemetry,
} from "./TelemetryDataModel";
import {AppInsightsCore} from "@microsoft/1ds-core-js";
import {ApplicationInsights, IExceptionTelemetry} from "@microsoft/1ds-wa-js";
import {CoreUtils, IAppInsightsCore, IConfiguration} from "@microsoft/applicationinsights-core-js";
import {
    IEventTelemetry,
    ITraceTelemetry,

    TelemetryItemCreator, Trace, Event, IDependencyTelemetry
} from "@microsoft/applicationinsights-common";
import {LogEventDiscriminator} from "./LogEventDiscriminator";


/**
 *  LogPolling periodically checks log queue and sends messages.
 */
export class LogPolling {
    public initialize: (config: IConfiguration, core: IAppInsightsCore) => void;
    public core: IAppInsightsCore;
    appInsightsCore: AppInsightsCore
    webAnalyticsPlugin: ApplicationInsights
    queue: Array<any>;
    public static envelopeName = "Ms.Web.ContentUpdate";


    constructor(webAnalyticsPlugin: ApplicationInsights, queue: Array<any>) {
        this.webAnalyticsPlugin = webAnalyticsPlugin;
        this.core = this.webAnalyticsPlugin.core;
        this.queue = queue;
        this.pollLogQueue();
    }

    /**
     * Periodically check queue for messages
     */
    pollLogQueue(): number {
        let interval = this.core.config.diagnosticLogInterval
        if (interval > 0) {
            interval = 10000;
        }

        return <any>setInterval(() => {
            const queue: Array<any> = this.queue;
            queue.forEach((logMessage: any) => {

                var eventType = this.getEventType(logMessage);
                switch (eventType) {
                    case LogEventDiscriminator.Trace: {
                        this.logTrace(logMessage as IMsTraceTelemetry)
                        break;
                    }
                    case LogEventDiscriminator.Event: {
                        this.logEvent(logMessage as IMsEventTelemetry)
                        break;
                    }
                    case LogEventDiscriminator.Exception: {
                        this.logError(logMessage as IMsExceptionTelemetry)
                        break;
                    }
                    case LogEventDiscriminator.Dependency: {
                        this.logDependency(logMessage as IMsDependencyTelemetry)
                        break;
                    }
                    default: {
                        console.log(`miss log event ${this.getEventType(logMessage)}`);
                        break;
                    }
                }
            });
            queue.length = 0;
        }, interval);
    }

    getEventType(object: any) {
        if (object && object.type) {
            return object.type;
        }
        return LogEventDiscriminator.None;
    }

    /**
     * Send 1DS Event
     * @param logMessage Event
     */
    logEvent(logMessage: IMsEventTelemetry) {
        let telemetryItem = TelemetryItemCreator.create<IEventTelemetry>(
            {name: logMessage.name},
            Event.dataType,
            LogPolling.envelopeName,
            this.core.logger,
            logMessage.properties
        );
        telemetryItem.time = CoreUtils.toISOString(logMessage.time);
        this.core.track(telemetryItem);
    }

    /**
     * Send 1DS Dependency Event
     * @param logMessage Event
     */
    logDependency(logMessage: IMsDependencyTelemetry) {
        let telemetryItem = TelemetryItemCreator.create<IDependencyTelemetry>(
            {
                id: logMessage.id,
                name: logMessage.name,
                duration: logMessage.duration,
                responseCode: logMessage.responseCode,
                target: logMessage.uri,
                success: logMessage.responseCode === 200
            },
            "DependencyData",
            LogPolling.envelopeName,
            this.core.logger,
            logMessage.properties
        );
        telemetryItem.time = CoreUtils.toISOString(logMessage.time);
            this.core.track(telemetryItem);
    }

    /**
     * Log 1DS message
     * @param logMessage Message
     */
    logTrace(logMessage: IMsTraceTelemetry) {
        let telemetryItem = TelemetryItemCreator.create<ITraceTelemetry>(
            {message: logMessage.message, severityLevel: logMessage.severityLevel as number},
            Trace.dataType,
            LogPolling.envelopeName,
            this.core.logger,
            logMessage.properties);
        telemetryItem.time = CoreUtils.toISOString(logMessage.time);
        this.core.track(telemetryItem);
    }

    /**
     * Send 1D error
     * @param logMessage Error
     */
    logError(logMessage: IMsExceptionTelemetry) {
        this.webAnalyticsPlugin.trackException(<IExceptionTelemetry>{
            exception: logMessage.exception,
            properties: logMessage.properties
        })
    }
}
