export class CredScanRedactor {
    constructor() {
    }

    // Redact sensitive data from event data
    redactEventData(data: any) {        
        const dataFields = ["refUri", "targetUri"];
        for (var d in data) {
            if (dataFields.indexOf(d) > -1)
                data[d] = this.credScanRedact(data[d]);
        }
    }

    // Redact sensitive data from event base data
    redactEventBaseData(data: any) {
        const dataFields = ["url", "uri", "refUri", "targetUri", "content"];
        for (var d in data) {
            if (dataFields.indexOf(d) > -1)
                data[d] = this.credScanRedact(data[d]);
        }
    }

    // Redact sensitive data from value returning a string with sensitive data censored
    credScanRedact(value: string): string {
        if (!value) return value;
        let str = value;

        // Define multiple regex patterns comming from IcMs
        let patterns = [
            /((microsoft\\.)?maps[\\s\\S]{0,40}|sig=)[a-z0-9\\-_]{43}/i, //Credential Type: General Password
            /\\W[a-z0-9/\\+]{86}==/i, //Credential Type: Azure Storage Account Access Key
            /x-(amz|goog)-(credential|security\\-token)/i, //Credential Type: Amazon web service pre-signed URL,
            /(\W[a-z0-9\/\+]{56}\W)/i, //Credential Type: Uncommon Symmetric Key
            /((app(lication)?|client|api)[_ \-]?(se?cre?t|key(url)?)|(refresh|twilio(account|auth))[_ \-]?(Sid|Token))([\s=:>]{1,10}|[\s"'=:|>,\]\\]{3,15}|["'=:\(]{2})(ConvertTo-SecureString[^"']+["'])?("data:text\/plain,.+"|[a-z0-9\/+=_.?\-]{8,200}[^\(\[\{;,\r\n]|[^\s"';<,\)]{5,200})/i, //Credential Type: Client Secret / Api Key
            /\WAIza[a-z0-9_\\\-]{35}\W/i, // Credential Type: Google API key
            /eyJ[a-zA-Z0-9\-_%]+\.eyJ/i, // Credential Type: Microsoft Entra Id Client Access Token
        ];

        // Function to replace matches with CENSORED
        // This is defined by CredScan team
        patterns.forEach(pattern => {
            str = str.replace(pattern, match => {
                return `CENSORED`;
            });
        });

        return str;
    }
}