export class ClientCommunicator {

    public clientActionDoneStatus: boolean;
    eventName: string;

    constructor(clientEventName: string) {
        this.eventName = clientEventName;
        this.addClientActionDoneListener();
    }

    public activateClientCommunications() {
        this.raiseEventWhenConsentIsRead();
    }

    private addClientActionDoneListener() {
        document.addEventListener(this.eventName, () => { this.setClientActionDone(); });
    }

    private raiseEventWhenConsentIsRead() {
        var consentEvent = new Event('consentIsReady', {bubbles: true});
        document.dispatchEvent(consentEvent);
    }

    private setClientActionDone() {
        this.clientActionDoneStatus = true;
    }
}