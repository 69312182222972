import {ITelemetryLog} from "../../../react-app/src/react-injectors/utils/ITelemetryLog";
import {LogSeverityLevel} from "../../../react-app/src/react-injectors/utils/LogSeverityLevel";
import {CustomWindow} from './CustomWindow';
import {
    IMsDependencyTelemetry,
    IMsEventTelemetry,
    IMsExceptionTelemetry,
    IMsTraceTelemetry
} from './TelemetryDataModel';
import {LogEventDiscriminator} from "./LogEventDiscriminator";


declare let window: CustomWindow;

/**
 * API to log Event, Exceptions and Log messages
 */
export class LogQueue implements ITelemetryLog{
    queue: Array<any> = new Array<any>();
    onedsConfigElement: Element;

    /**
     * API to send logs
     * @param msg
     * @param severity
     */
    public trace(msg: string, severity?: LogSeverityLevel, customProperties?: { [key: string]: any }) {
        const traceEvent: IMsTraceTelemetry = {
            type: LogEventDiscriminator.Trace,
            message: msg,
            severityLevel: severity || LogSeverityLevel.Information,
            time: new Date(),
            properties: customProperties
        }
        this.queue.push(traceEvent);
    }

    /**
     * API to send custom event
     * @param name  Event name
     * @param customProperties Event ptoperties
     */
    public event(name: string, customProperties?: { [key: string]: any }) {
        const traceEvent: IMsEventTelemetry = {
            type: LogEventDiscriminator.Event,
            name: name,
            time: new Date(),
            properties: customProperties
        }
        this.queue.push(traceEvent);
    }

    /**
     * API to send Exception event
     * @param error Exception event
     * @param customProperties Exception properties
     */
    public error(error: Error, customProperties?: { [key: string]: any }) {
        const traceEvent: IMsExceptionTelemetry = {
            type: LogEventDiscriminator.Exception,
            exception: error,
            properties: customProperties,
            time: new Date()
        }
        this.queue.push(traceEvent);
    }

    /**
     * API to send Dependency event
     */
    public dependency(id: string,
               statusCode: number,
               name?: string,
               uri?: string,
               duration?: number,
               customProperties?: { [p: string]: any }): void {
        const traceEvent: IMsDependencyTelemetry = {
            id: id,
            name: name,
            uri: uri,
            duration: duration,
            responseCode: statusCode,
            type: LogEventDiscriminator.Dependency,
            time: new Date(),
            properties: customProperties
        }
        this.queue.push(traceEvent);
    }
}
